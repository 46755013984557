<template>
  <div class="add-access">
    <div class="title">{{title}}</div>
    <div class="cell">
      <el-form
        size="mini"
        style="width: 500px"
        ref="form"
        :rules="rules"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="功能类型" prop="is_menu">
          <el-radio-group v-model="form.is_menu">
            <el-radio :label="1">菜单</el-radio>
            <el-radio :label="2">按钮</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="功能名称" prop="name">
          <el-input v-model="form.node_name"></el-input>
        </el-form-item>
        <el-form-item label="所属父级">
          <el-cascader
            style="width: 100%"
            :show-all-levels="false"
            :props="props"
            v-model="form.pid"
            :options="options"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="控制器名" prop="control_name">
          <el-input v-model="form.control_name"></el-input>
        </el-form-item>
        <el-form-item label="方法名" prop="action_name">
          <el-input v-model="form.action_name"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">确认</el-button>
          <el-button @click="handleBack" plain>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { nodeList, nodeAdd, nodeEdit } from "@/api/admin.js";
export default {
  data() {
    return {
      form: {
        is_menu: 1,
        id: 0,
        name: "",
        node_name: "",
        pid: 0,
        control_name: "",
        action_name: "",
        sort: "",
      },
      options: [],
      props: {
        value: "id",
        label: "node_name",
        checkStrictly: true,
        emitPath: false,
      },
      rules: {
        is_menu: [
          { required: true, message: "请选择功能类型", trigger: "blur" },
        ],
        node_name: [
          { required: true, message: "请输入功能名称", trigger: "blur" },
        ],
        control_name: [
          { required: true, message: "请输入控制器名", trigger: "blur" },
        ],
        action_name: [
          { required: true, message: "请输入方法名", trigger: "blur" },
        ],
      },
      title:"创建"
    };
  },
  created() {
    this.getNodeList();
    if (sessionStorage.ADMIN_NODE_INFO) {
      this.title="编辑"
      this.form = JSON.parse(sessionStorage.ADMIN_NODE_INFO);
      delete this.form.time;
      delete this.form.children;
    }
  },
  methods: {
    getNodeList() {
      nodeList().then((res) => {
        if (res.code == 1) {
          this.options = res.data;
          this.options.unshift({
            node_name: "顶级",
            id: 0,
          });
          this.formattingData(this.options);
        }
      });
    },
    // 格式化数据
    formattingData(arr) {
      arr.forEach((item) => {
        if (item.children && item.children.length != 0) {
          this.formattingData(item.children);
        } else {
          delete item.children;
        }
      });
    },
    onSubmit() {
      this.form.name = this.form.node_name;
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id == 0) {
            nodeAdd(this.form).then((res) => {
              if (res.code == 1) {
    
                this.$message.success("添加菜单成功");
                this.handleBack();
              }
            });
          } else {
            nodeEdit(this.form).then((res) => {
              if (res.code == 1) {
   
                this.$message.success("编辑菜单成功");
                this.handleBack();
              }
            });
          }
        }
      });
    },
    handleBack() {
      this.$router.replace("accessMenu");
    },
    handleChange() {},
  },
};
</script>
<style lang="less">
.add-access {
  .title {
    font-size: 18px;
    padding-bottom: 20px;
  }
  .cell {
    background: #fff;
    padding: 20px;
  }
}
.el-form-item__label {
  font-size: 12px;
}
.el-radio__label {
  font-size: 12px;
}
</style>